.contact-info {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
  text-align: left;
  padding-top: 50px;
  font-size: medium;
}

.social-link {
  text-decoration: none;
  color: #000;
}

.info-list {
  color: #a9a9a9;
  text-transform: none;
  margin-top: 20px;
  font-size: smaller;
  list-style-type: none;
}

.info-email {
  height: auto;
  width: 50%;
  margin-right: 100px;
}

.info-social {
  height: auto;
  width: 50%;
}

.container {
  display: grid;
  height: auto;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1.5fr;
  grid-template-areas:
    'sidebar main main main'
    'sidebar main main main';
  text-transform: uppercase;
  font-size: 12px;
  padding: 20px 50px 50px;
}

main {
  grid-area: main;
  position: relative;
  font-size: 200%;
}

#sidebar {
  grid-area: sidebar;
  overflow: hidden;
  width: 700px;
  height: 1500px;
  /* filter: grayscale(); */
}

.contact-photographer {
  margin: -10px 0px 0px -600px;
}

.contact-photographer:hover {
  filter: none;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: none;
}

@media only screen and (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1.2fr 2.2fr;
    grid-template-areas:
      'sidebar'
      'main';
  }
  .contact-photographer {
    width: 110%;
    margin: -10px 0px 0px -10px;
  }

  main {
    width: 100%;
    height: auto;
  }

  div#sidebar {
    width: fit-content;
    height: auto;
  }

  .text-container {
    width: 100%;
  }
  .contact-info {
    flex-direction: column;
  }

  .info-social {
    margin-top: 60px;
  }
}

input[type='text'],
input[type='email'],
select,
textarea {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
button[type='submit'] {
  background-color: #000;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type='submit']:hover {
  background-color: gray;
  transition: all 0.3s ease-in-out;
}
/* Add a background color and some padding around the form */
.text-container {
  border-radius: 5px;
  padding: 20px;
}
